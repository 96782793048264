import React, { useState } from "react";
import { useFetchClientsQuery, useFetchJobcardsHistoryQuery, useFetchJobcardsQuery } from "../../store/store";
import { useSelector } from "react-redux";
import FilterPaginatedTable from "../../components/tables/FilterPaginatedTable";
import { IDate, IDate4, MakeDate } from "../../atoms/static/Functions";
import Cards from "../../components/cards/Cards";
import Heading from "../../atoms/heading/Heading";
import { Button, DatePicker, Form, message } from "antd";
import { FaEye } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import FilterTable from "../../components/tables/FilterTable";

const Report = () => {
  const { jobcard_page, user } = useSelector((state) => state.user);
  const [dateAndTime, setDateAndTime] = useState("");
  const [dateRange, setDateRange] = useState([null, null]);
  const navigate = useNavigate()
  // const {
  //   data: cards,
  //   isLoading: loading,
  //   isFetching: fetchJob,
  // } = useFetchJobcardsQuery({page:jobcard_page,stage:user?.role,stage_filter:"",client_filter:""});
  // const {
  //   data: historyData,
  //   isLoading: loading_history,
  //   isFetching: fetchJob_history,
  // } = useFetchJobcardsHistoryQuery({ date: dateAndTime });
  const {
    data: historyData,
    isLoading: loading_history,
  } = useFetchJobcardsHistoryQuery({
    date: dateRange[0] && dateRange[1]
      ? `from_created_time=${MakeDate(dateRange[0])}&to_created_time=${MakeDate(dateRange[1])}`
      : "",
  });
  const {
    data: clients,
    isLoading: clients_loading,
    isFetching: clients_fetch,
  } = useFetchClientsQuery();

  const columns = [
    {
      title: "Job Card No.",
      dataIndex: "id",
      key: "id",
      width: "10%",
      render: (_, record, index) => <>{index + 1}</>,
    },

    {
      title: "Client Company",
      dataIndex: "client",
      key: "client",
      render: (_, record, index) => <>{clients?.filter((item) => item.id === record.client)[0].company_name}</>,

    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      // sorter: (a, b) =>
      //   moment(a.date, "DD/MM/YYYY").unix() -
      //   moment(b.date, "DD/MM/YYYY").unix(),
    },
    {
      title: "Deliver Date",
      dataIndex: "deliver_date",
      key: "deliver_date",
    },
    {
      title: "Last Updated By",
      dataIndex: "last_update",
      key: "last_update",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Current Stage",
      dataIndex: "stage",
      key: "stage",
    },
    {
      title: "Created At",
      dataIndex: "created_time",
      key: "created_time",
      render: (_, record) => <>{IDate(record?.created_time)}</>,
    },
  ];
  const columns2 = [
    {
      title: "Job Card No.",
      dataIndex: "id",
      key: "id",
      width: "10%",
      render: (_, record, index) => <>{index + 1 + jobcard_page * 10 - 10}</>,
    },
    {
      title: "Client Company",
      dataIndex: ["client", "company_name"],
      key: ["client", "company_name"],
    },
    // {
    //   title: "Client Company",
    //   dataIndex: "id",
    //   key: "id",
    // },


    {
      title: "Current Stage",
      dataIndex: "stage",
      key: "stage",
    },
    {
      title: "Created At",
      dataIndex: "created_time",
      key: "created_time",
      render: (_, record, text) => <>{IDate(record?.created_time)}</>,
    },
    {
      title: "Sublimation Time",
      dataIndex: "sublimation_time",
      key: "sublimation_time",
      render: (_, record, text) => <>{IDate(record?.sublimation_time)}</>,
    },
    {
      title: "Cutting Time",
      dataIndex: "cutting_time",
      key: "cutting_time",
      render: (_, record, text) => <>{IDate(record?.cutting_time)}</>,
    },
    {
      title: "Stitching Time",
      dataIndex: "stitching_time",
      key: "stitching_time",
      render: (_, record, text) => <>{IDate(record?.stitching_time)}</>,
    },
    {
      title: "Packaging Time",
      dataIndex: "packaging_time",
      key: "packaging_time",
      render: (_, record, text) => <>{IDate(record?.packaging_time)}</>,
    },
    {
      title: "Account Time",
      dataIndex: "account_time",
      key: "account_time",
      render: (_, record, text) => <>{IDate(record?.account_time)}</>,
    },
    {
      title: "Completed Time",
      dataIndex: "completed_time",
      key: "completed_time",
      render: (_, record, text) => <>{IDate(record?.completed_time)}</>,
    },
    {
      title: "View",

      key: "view",
      render: (_, record) => (
        <Button
          size="small"
          className="paginated-table-button"
          onClick={(e) => {
            e.stopPropagation();
            navigate(`/detail-jobcard/${record.id}`);

          }}

        >
          <FaEye />
        </Button>
      ),
    },
  ];
  // const handleFinish = (data) => {
  //   if (data?.from && data?.to) {

  //     const newData = `&from_created_time=${MakeDate(data.from)}&to_created_time=${MakeDate(data.to)}`
  //     setDateAndTime(newData);
  //   } else if (!data?.from && !data?.to) {
  //     setDateAndTime("");

  //   }
  //   else {
  //     message.error("Please Select Date")
  //   }

  // };
  const handleFinish = (data) => {
    if (data?.from && data?.to) {
      setDateRange([data.from, data.to]);
    } else if (!data?.from && !data?.to) {
      setDateRange([null, null]);
    } else {
      message.error("Please Select Date");
    }
  };

  
  const generateCSV = () => {
    if (!historyData?.length) {
      message.warning("No data available to export");
      return;
    }
  
    const csvHeader = [
      "Job Card No.",
      "Client Company",
      "Order By",
      "Date",
      "Deliver Date",
      "Created At",
      "Quantity",
      "Current Stage",
      "Designer Time",
      "Sublimation Time",
      "Cutting Time",
      "Stitching Time",
      "Packaging Time",
      "Account Time",
      "Completed Time",
    ];
  
    const csvRows = historyData.map((jobcard) => {
      const jobcardData = [
        jobcard.id,
        jobcard.client?.company_name || "",
        jobcard.order_by || "",
        jobcard.date || "",
        jobcard.deliver_date || "",
        IDate4(jobcard.created_time),
        jobcard.quantity || "",
        jobcard.stage || "",
        IDate4(jobcard.designer_time),
        IDate4(jobcard.sublimation_time),
        IDate4(jobcard.cutting_time),
        IDate4(jobcard.stitching_time),
        IDate4(jobcard.packaging_time),
        IDate4(jobcard.account_time),
        IDate4(jobcard.completed_time),
      ];
      // Wrap each value in quotes to handle commas within data correctly
      return jobcardData.map(item => `"${item}"`).join(",");
    });
  
    const csvContent = [csvHeader.join(","), ...csvRows].join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
  
    const link = document.createElement("a");
    link.href = url;
    link.download = `report_${new Date().toISOString()}.csv`;
    link.click();
    URL.revokeObjectURL(url);
  };
  
  
  
  


  console.log("History Data", historyData);
  return (
    <div
      className="body-width"
      style={{
        margin: "20px auto",
        padding: "20px",
        boxShadow: "0px 0px 4.2px rgba(0, 0, 0, 0.25)",
      }}
    >
      <div style={{ marginBottom: "30px" }}>
        <Cards date={dateRange} />
        <Heading title={"Report Table"} />
      </div>
      {/* <FilterPaginatedTable
        columns={columns}
        data={cards}
        page={jobcard_page}
        page100={"jobcard"}
        navi={"report"}
      /> */}
      <div style={{ margin: "20px 0px" }}>
        <Form layout="inline" onFinish={handleFinish}>
          <Form.Item label="From Date" name="from">
            <DatePicker format={"DD/MM/YYYY"} />
          </Form.Item>
          <Form.Item label="To Date" name="to">
            <DatePicker format={"DD/MM/YYYY"} />
          </Form.Item>

          {/* submit button */}
          <Form.Item style={{ textAlign: "center" }}>
            <Button type="primary" htmlType="submit" size="middle">
              Search
            </Button>
          </Form.Item>
          <Button onClick={generateCSV}>
            <div>EXPORT</div>
          </Button>
        </Form>
      </div>
      <FilterTable
        columns={columns2}
        data={historyData}
        // page={jobcard_page}
        // page100={"jobcard"}
        navi={"report"}
      />
      {/* <FilterPaginatedTable
        columns={columns2}
        data={cards}
        page={jobcard_page}
        page100={"jobcard"}
        navi={"report"}
      /> */}
    </div>
  );
};

export default Report;

import { Table } from 'antd';
import React from 'react';
import './customtable.css';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { JobCard_page } from '../../store/mutation/UserSlice';
import styled from 'styled-components';

export const CardStyle = styled.div`
  .priority-0 {
    border-left: 4px solid grey;
    &:hover {
      td {
        background: grey !important;
      }
    }
  }

  .priority-1 {
    border-left: 4px solid #00ff2a;
    &:hover {
      td {
        background: #00ff2a !important;
      }
    }
  }

  .priority-2 {
    border-left: 4px solid #00ccff;
    &:hover {
      td {
        background: #00ccff !important;
      }
    }
  }

  .priority-3 {
    border-left: 4px solid #f5864f;
    &:hover {
      td {
        background: #f5864f !important;
      }
    }
  }

  .priority-4 {
    border-left: 4px solid #ff3c01;
    &:hover {
      td {
        background: #ff3c01 !important;
      }
    }
  }

  .ant-table table {
    border-collapse: collapse;
  }

  .title {
    font-size: 1.8rem;
    text-align: center;
    width: 100%;
    border-bottom: 1px solid grey;
    padding-bottom: 10px;
    font-weight: bold;
  }

  box-sizing: border-box;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 5px;

  .table-row {
    cursor: pointer;
  }
  
  flex-wrap: wrap;
`;

const FilterTable = ({ columns, data, navi }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  return (
    <div>
      <CardStyle>
        <Table
          size='medium'
          className='custom-table'
          dataSource={data}
          columns={columns}
          onRow={(record) => {
            return {
              onClick: () => {
                if (navi === "report") {
                  navigate(`/reports-detail/${record.id}`);
                } else {
                  navigate(`/detail-jobcard/${record.id}`);
                }
              },
              className: `priority-${record?.priority} cursor`,
            };
          }}
        />
      </CardStyle>
    </div>
  );
};

export default FilterTable;
